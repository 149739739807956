import React, { useState } from 'react'
import H2 from '../../core/H2'
import H3 from '../../core/H3'
import P from '../../core/P'

export default function VerticalTabs({ VerticalTab: { title, tabs = [] } }) {
    const [openTab, setOpenTab] = useState('div1')
    const activedata = tabs[0]
    const [data, setData] = useState(activedata)
    return (
        <div className='bg-white py-20 md:px-6 px-4'>
            <div className='xl:max-w-6xl lg:max-w-4xl md:max-w-3xl mx-auto flex flex-wrap relative overflow-hidden' data-aos="fade-up">
                <div className='md:w-2/5 w-full overflow-hidden '>
                    {title && <H2 title={title} className="text-center md:text-left" />}
                    <div className='flex md:flex-col lg:mt-10 mt-8 overflow-auto pb-2 md:pb-0 '>
                        {tabs && (
                            tabs.map((e) => {
                                return <div key={Math.random()} className="flex items-center pr-8 md:pr-0 lg:py-3 py-2" >
                                    <p
                                        className={`text-xl font-semibold cursor-pointer whitespace-nowrap  md:pl-4 leading-tight hover:text-blue-500  ${openTab === e.divId ? ' md:border-l-4 md:border-blue-500 text-blue-500' : 'md:border-l-4 text-shark-500 md:border-transparent'}`}
                                        onClick={() => {
                                            setOpenTab(e?.divId)
                                            setData(e)
                                        }} aria-hidden="true">{e?.tabTitle}
                                    </p>
                                </div>
                            }))
                        }
                    </div>
                </div>
                <div className='md:w-3/5 pt-5 md:pt-0  w-full relative transform transition-all duration-500 ' >
                    {
                        <div className={`${openTab === data?.divId ? "block" : "hidden"
                            }`}>
                            <div className='h-auto w-full flex overflow-hidden relative ' >
                                <a href={data?.pageDetailLink?.url} target={data?.pageDetailLink?.target } rel="noopener noreferrer">
                                    <img
                                        src={data?.image?.mediaItemUrl}
                                        alt={data?.image?.altText ? data?.image?.altText : "vertical tab"}
                                        width={data?.image?.mediaDetails?.width}
                                        height={data?.image?.mediaDetails?.height}
                                        title={data?.image?.altText ? data?.image?.altText : "vertical tab"}
                                        loading="lazy"
                                        className="w-full rounded-lg h-auto object-cover " />
                                </a>
                            </div>
                            <div>
                                <a href={data?.pageDetailLink?.url} target={data?.pageDetailLink?.target} rel="noreferrer"  aria-label="go to page"><H3 title={data?.tabTitle} className="text-shark-500 pt-8 inline-block" /></a>
                                <P title={data?.subTitle} noPad={true} className="pt-4 leading-8 text-shark-400" />
                            </div>
                            {
                                data && data?.caseStudyLink &&
                                <div className='pt-4'>
                                    <P title="Our Work" noPad={true} className="text-shark-500 font-medium" />
                                    <div className='pt-1 flex md:flex-row flex-col flex-wrap  '>
                                        {
                                            (data &&
                                                data?.caseStudyLink.map((val, index) => {
                                                    return <div className='py-1 my-2 lg:mr-8 md:mr-4' key={index}>
                                                        <a href={val?.link?.url}><P title={val?.link?.title}  className="text-blue-500 py-1 px-8 rounded inline-block transition-all duration-200 bg-blue-200 hover:bg-blue-500 hover:text-white" noPad={true} /> </a>
                                                    </div>
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            <div className='mt-4 py-2 transition-all duration-500'>
                                {data?.pageDetailLink?.url && <a href={data?.pageDetailLink?.url}  target={data?.pageDetailLink?.target} rel="noopener noreferrer"><P title={data?.pageDetailLink?.title} className="text-blue-500 font-bold hover:text-blue-600 inline-block" noPad={true} /> </a>}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
